import React from "react";
import { Button } from 'primereact/button';
import { useRef, useEffect, useCallback, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";

export default function DownloadPage() {

    const toast = useRef(null);
    const [accessToken, setAccessToken] = useState([]);
    const [code, setCode] = useState([]);
    const [key, setKey] = useState([]);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [documentContent, setDocumentContent] = useState(null);
    const [dataContent, setDataContent] = useState(null);


    //for the form

    const defaultValues = {
        value: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({ defaultValues });

    const onSubmit = (formData) => {
        const fileKey = formData.value;

        if (fileKey) {
            setKey(fileKey)
        } else {
            console.log("fileKey is null in onSubmit")
        }
    };

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };



    //fetch accessToken from apiUrl

    const fetchAccessToken = async (code) => {
        // Construct the API URL
        const apiUrl = `https://www.figma.com/api/oauth/token?client_id=l1issw91rdpMaP4rOXFz7y&client_secret=gexrV9ZyJzDWkifP7PZdoqt1rsxp0X&redirect_uri=https://figma-backup.pages.dev/download&code=${code}&grant_type=authorization_code`;

        // Make a POST request to fetch the access token
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
            });

            if (response.ok) {
                const data = await response.json();
                const accessToken = data.access_token;
                setAccessToken(accessToken)


                // Now you have the access token, you can use it for API requests

            } else {
                console.error('Failed to fetch access token');
            }
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    };


    //fetch json and thumbnail from apiUrl

    const fetchFigmaData = useCallback(async (accessToken, fileKey) => {
        const apiUrl = `https://api.figma.com/v1/files/${fileKey}`;
        // Replace with the specific API endpoint you want to access

        //test key: CTcFPkgyUSECUBJa6oN1dL

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Figma API Response:', data);

                // Extract the thumbnailUrl from the data and set it to the state

                const thumbnail = data.thumbnailUrl;
                const documentData = JSON.stringify(data.document);

                //dataJSON is the whole json data 

                const dataJSON = JSON.stringify(data);
                setDocumentContent(documentData);
                setDataContent(dataJSON);

                if (thumbnail) {

                    setThumbnailUrl(thumbnail);

                } else {
                    console.log("no thumbnail")
                }
            } else {
                console.error('Failed to fetch Figma API data');
            }
        } catch (error) {
            console.error('Error fetching Figma API data:', error);
        }
    }, [setThumbnailUrl]);


    //useEffects


    useEffect(() => {


        // Check if the URL has a code parameter (callback from Figma)

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        setCode(code);

    }, []);

    useEffect(() => {

        //if code is correct fetch the access token

        if (code) {
            fetchAccessToken(code);

        }
    }, [code]);

    useEffect(() => {
        //if the accessToken and figma file key exist fetch json and thumbnail of the figma file

        if (accessToken && key) {

            fetchFigmaData(accessToken, key); // Call this function when you have the access token


        }
    }, [accessToken, fetchFigmaData, key]);






    return (
        <>
            <div className="p-d-flex p-jc-center p-ai-center" style={{ height: "100vh" }}>
                <div className="card p-d-flex p-flex-column p-jc-center p-align-center" style={{ height: "80vh" }}>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-grid p-dir-col p-justify-center">
    
                        <div className="p-col-fixed p-d-flex p-jc-center">
                            <Toast ref={toast} />
                            <Controller
                                name="value"
                                control={control}
                                rules={{ required: 'Figma key is required' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className={classNames({ 'p-error': errors.value })}></label>
                                        <span className="p-float-label">
                                            <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                            <label htmlFor={field.name}>Figma project id or file key</label>
                                        </span>
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
    
                        <div className="p-col-fixed p-d-flex p-jc-center p-mt-2">
                            <Button label="Submit" type="submit" icon="pi pi-check" />
                        </div>
    
                        {documentContent && (
                            <div className="document-content p-col-12 p-d-flex p-flex-column p-ai-center p-jc-center p-mt-4">
                                <h4 className="text-2xl">Data Content:</h4>
                                <p style={{ overflowWrap: "break-word", wordWrap: "break-word", maxWidth: "100%", textAlign: "center", margin: "15px 0" }}>
                                    {dataContent}
                                </p>
                                <h4 className="text-2xl">Thumbnail:</h4>
                                {thumbnailUrl && <a href={thumbnailUrl} target="_blank" rel="noopener noreferrer" style={{ color: "#007bff", textDecoration: "underline", margin: "10px 0" }}>{thumbnailUrl}</a>}
                            </div>
                        )}
    
                    </form>
                </div>
            </div>
        </>
    )
    
    
}

