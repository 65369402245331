
import React, { useState } from 'react'


function AuthPage() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState("")


    const handleLogin = async (providerName, event) => {
        event.preventDefault();
        setError("");
        try {
            const res = await fetch(`http://localhost:8080/api/auth/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email, password
                })
            });
            const data = await res.json();
            if (data.message) {
                setError(data.message)
            }
            if (data.token) {
                localStorage.setItem("token", data.token)
                window.location.href = "/dashboard"
            }
        } catch (err) {
            console.log(err)
        }
      };

return (
    <>
      {/*
          This example requires updating your template:

          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
        <div id="test">
      <div className='flex min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-100'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md mb-8 text-center'>
          <img
            className='mx-auto mb-6'
            alt='Figma Backup'
          />
          <h2 className='text-2xl lg:text-3xl xl:text-4xl font-semibold'>
            Haz backup
            <span className="block text-secondary">de tus ficheros Figma</span>
          </h2>
          <p className="text-sm mt-2">
            App test para hacer backup de ficheros Figma
          </p>
        </div>
        <div id="container">
        <div id="recaptcha-container"></div>
          <div className='sm:mx-auto sm:w-full sm:max-w-[480px]'>
            <div className='bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12'>
              <form className='space-y-6' onSubmit={event => handleLogin('email', event)}>
                <div>
                  <label htmlFor='email' className='block text-sm font-medium leading-6 text-gray-900'>
                    Usuario
                  </label>
                  <div className='mt-2'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      autoComplete='email'
                      required
                      value={email} onChange={e => setEmail(e.target.value)}
                      className='block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor='password' className='block text-sm font-medium leading-6 text-gray-900'>
                    Contraseña
                  </label>
                  <div className='mt-2'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      autoComplete='current-password'
                      required
                      value={password} onChange={e => setPassword(e.target.value)}
                      className='block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>

                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <input
                      id='remember-me'
                      name='remember-me'
                      type='checkbox'
                      className='h-4 w-4 rounded border-gray-300 text-secondary focus:ring-secondary'
                    />
                    <label htmlFor='remember-me' className='ml-3 block text-sm leading-6 text-gray-900'>
                      Recordar contraseña
                    </label>
                  </div>
                  <div className='text-sm leading-6'>
                  </div>
                </div>
                <div id="sign-in">
                  <button id="sign-in-button"
                    type='submit'
                    className='flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black'
                    label="Open Double Authentication Modal"
                    //onClick={handleClickLogin}
                  >
                      <a
  id="sign-in-button"
  href={`https://www.figma.com/oauth?client_id=l1issw91rdpMaP4rOXFz7y&redirect_uri=https://figma-backup.pages.dev/download&scope=files:read&state=:state&response_type=code`}
  className='flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black'
>
  Entrar
</a>
                    </button>
                  </div>
                  {error && <p>{error}</p>}
                </form>

            </div>

          </div>
        </div>
      </div>

    
 
    </div>
    </>
  )
}

export default AuthPage;