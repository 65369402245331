import './App.css';
import DownloadPage from './pages/DownloadPage';
import AuthPage from './pages/AuthPage';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";


export default function AppRoutes() {
  return (
    <Router>
      <Routes>

          <Route path="/" element={<AuthPage />} />
          <Route path="/download" element={<DownloadPage />} />
         
      </Routes>
    </Router>
  );
}